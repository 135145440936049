import React, { useState } from "react"
import { Helmet } from "react-helmet"
import {
  faUsers,
  faCalculator,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import Button from "@material-ui/core/Button"

import Layout from "../../components/layout"
import ColoredHeader from "../../components/coloredHeader"
import CharacterCard from "../../components/characterCard"
import AllMightyCard from "../../components/allMightyCard"
import Separator from "../../components/separator"
import PeopleModal from "../../components/peopleModal"
import FloatingSearch from "../../components/floatingSearch"

import atlas_lg_blackwhite from "../../../static/background_images/atlas_lg_blackwhite.jpg"

import Janet from "../../../static/directors/CLARK_Janet.png"
import Charlie from "../../../static/directors/CRISP_Charles.png"
import Daniels_Bob from "../../../static/directors/DANIELS_Bob.png"
import Dugle_Lynn from "../../../static/directors/DUGLE_Lynn.png"
import YACOB_Ezra from "../../../static/people/Ezra_Yacob.jpg"
import Gaut_Chris from "../../../static/directors/GAUT_Cris.png"
import julie_roberston2 from "../../../static/directors/ROBERTSON_Julie.png"
import Textor_Donald from "../../../static/directors/TEXTOR_Donald.png"
import MikeKerr from "../../../static/directors/KERR_Mike.png"

const audit_charter = "/documents/audit_charter.pdf"
const comp_charter = "/documents/comp_charter.pdf"
const nomgov_charter = "/documents/nomgov_charter.pdf"

const corpgov_guidelines = "/documents/corpgov_guidelines.pdf"
const conduct_employees = "/documents/eog_code_of_conduct-directors-and-employees.pdf"
const conduct_vendors = "/documents/eog_code_of_conduct-vendors-and-contractors.pdf"
const communications_board = "/documents/communications_board.pdf"
const complaint_audit = "/documents/complaint_audit.pdf"
const human_rights = "/documents/human-rights-policy.pdf"
const ethics_sfo = "/documents/ethics_sfo.pdf"
const bylaws = "/documents/bylaws.pdf"
const political_activities_and_contributions =
  "/documents/EOG Political Activities and Trade Associations Policy (updated policy approved by NGSC on 2-6-2023).pdf"

export default function BoardOfDirectors(props) {
  const themeColor = "#da3433"

  const [htmlContent] = useState(null)

  const [modalState, setModalState] = useState({
    open: false,
    paragraphs: [],
    name: "",
    title: "",
  })

  const onClose = () => {
    setModalState({ open: false, paragraphs: [], name: "", title: "" })
  }

  return (
    <Layout>
      <Helmet>
        <title>Board of Directors – EOG Resources, Inc.</title>
      </Helmet>
      <ColoredHeader
        title="COMPANY"
        header="Board of Directors"
        overlayColor1="#d50000"
        overlayColor2={themeColor}
        lineColor="#f95c4f"
        bgImageSRC={atlas_lg_blackwhite}
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>
      <div className="page leadership-page no-padding">
        <div className="max-width-container">
          <section className="character-cards-container  five-long spaced">
            <CharacterCard
              listOrParagraph="list"
              name="Janet Clark"
              paragraphs={[
                "Director since 2014",
                "Retired Executive Vice President and Chief Financial Officer, Marathon Oil Corporation",
                "Chairperson: Nominating, Governance and Sustainability Committee",
              ]}
              ratio="3/4"
              imageURL={Janet}
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "JANET F. CLARK",
                  title: "Director since 2014",
                  paragraphs: [
                    `Ms. Clark has extensive leadership and financial experience, having most recently served as Executive Vice President and Chief Financial Officer of Marathon Oil Corporation (“Marathon”) from January 2007 until her retirement in October 2013. Prior to that, she was Senior Vice President and Chief Financial Officer of Marathon from January 2004 to January 2007. From 2001 through 2003, Ms. Clark served as Senior Vice President and Chief Financial Officer of Nuevo Energy Company and, from 1997 until 2000, she held various roles at Santa Fe Snyder Corporation, including Chief Financial Officer and Executive Vice President of Corporate Development and Administration.`,
                    `Ms. Clark is also a director of Texas Instruments Incorporated (since 2015), a publicly traded global semiconductor design and manufacturing company, where she serves as Chair of the Audit Committee and previously served as lead director and as a member of the Governance and Stockholder Relations Committee.`,
                    `Ms. Clark is also a member of the Board of Directors of Resources for the Future (since 2021), an independent, nonprofit research institution based in Washington, D.C. whose mission is to improve environmental, energy and natural resource decisions through impartial economic research and policy engagement.`,
                    `From 2015 to 2018, Ms. Clark served as a director of Goldman Sachs BDC, Inc., a specialty finance company and regulated management investment company, where she served as a member of the Audit, Compliance, Compensation, Contract Review, and Governance and Nominating Committees. Ms. Clark also previously served as a director of Goldman Sachs Private Middle Market Credit LLC, a regulated investment company and business development company, from 2016 to 2018.`,
                    `In addition, Ms. Clark served on the Board of Directors and Audit Committee of Dell Inc. from September 2011 to October 2013, including service as chairperson of the Audit Committee during 2013. Ms. Clark also served on the Board of Directors of Exterran Holdings, Inc. (and its predecessor company, Universal Compression Holdings, Inc.) from 2003 until 2011, including service as Audit Committee chairperson from 2004 to 2011.`,
                    `Chairperson, Nominating, Governance and Sustainability Committee; Member, Audit and Compensation and Human Resources Committees`,
                  ],
                })
              }}
            />
            <CharacterCard
              listOrParagraph="list"
              name="Charlie Crisp"
              ratio="3/4"
              paragraphs={[
                "Director since 2002",
                "Retired President and Chief Executive Officer, Coral Energy, LLC",
              ]}
              imageURL={Charlie}
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "CHARLES R. CRISP",
                  title: "Director since 2002",
                  paragraphs: [
                    `Mr. Crisp began his career in the oil and gas industry over 50 years ago with Conoco Inc. and has held senior management positions with numerous energy companies, including (i) Coral Energy, LLC, a subsidiary of Shell Oil Company, where he served as President and Chief Executive Officer from 1999 until his retirement in November 2000 and as President and Chief Operating Officer from 1998 to 1999; (ii) Houston Industries Incorporated, where he served as President of the power generation group from 1996 to 1998; and (iii) Tejas Gas Corporation, a major intrastate natural gas pipeline company, where he served as President, Chief Operating Officer and a director from 1988 to 1996.`,
                    `Mr. Crisp has also accumulated over 20 years of experience as a director of publicly traded energy companies. Mr. Crisp is currently a director of Targa Resources Corp. (since 2005), a provider of midstream natural gas and natural gas liquids services, where he currently serves on the Sustainability and Nominating and Governance Committees. Mr. Crisp also serves as a director of Targa Resources GP, LLC, a subsidiary of Targa Resources Corp.`,
                    `Mr. Crisp served as a director of Intercontinental Exchange, Inc. (“ICE”) from 2002 to 2022, including service on the Compensation and Audit Committees. ICE is an operator of regulated exchanges, trading platforms and clearing houses.`,
                    `In addition, Mr. Crisp served as a director of Southern Company Gas (formerly, AGL Resources Inc.) from 2003 to 2023. Southern Company Gas is a wholly owned subsidiary of Southern Company and a leading provider of natural gas and electric utilities.`,
                    `Mr. Crisp also served on the Board of Directors of AGL Resources Inc. (a then-publicly traded company providing natural gas distribution and marketing services) from 2003 to 2016, where he also served as a member of the Compensation Committee and Executive Committee and as chairperson of the Finance and Risk Management Committee. In 2016, AGL Resources Inc. was acquired by, and became a wholly owned subsidiary of, Southern Company.`,
                    `Member, Audit, Compensation and Human Resources, and Nominating, Governance and Sustainability Committees`,
                  ],
                })
              }}
            />
            <CharacterCard
              listOrParagraph="list"
              name="Bob Daniels"
              ratio="3/4"
              paragraphs={[
                "Director since 2017",
                "Retired Executive Vice President, International and Deepwater Exploration, Anadarko Petroleum Corporation",
                "2024 Presiding Director",
              ]}
              imageURL={Daniels_Bob}
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "ROBERT P. DANIELS",
                  title: "Director since 2017",
                  paragraphs: [
                    `Mr. Daniels has extensive experience in the oil and gas exploration and production industry. Mr. Daniels served in various senior management positions during his 32-year career with Anadarko Petroleum Corporation (“Anadarko”), a publicly traded oil and gas exploration and production company. Prior to his retirement in December 2016, Mr. Daniels served as President, Anadarko Canada, from 2001 to 2004, as Senior Vice President, Exploration and Production, from 2004 to 2006, as Senior Vice President, Worldwide Exploration, from 2006 to 2013, and as Executive Vice President, International and Deepwater Exploration, from 2013 to 2016. Mr. Daniels was a member of Anadarko’s executive committee from 2004 to 2016 and also served as an executive committee representative to the Governance and Risk Committee of Anadarko’s Board of Directors.`,
                    `Since 2010, Mr. Daniels has served on the Board of Directors of MicroSeismic, Inc. (“MicroSeismic”) as an independent, non-executive director. Mr. Daniels is also a member of MicroSeismic’s Nominations & Governance, Audit, and Compensation Committees. MicroSeismic is an oilfield services company providing completions evaluation services and real-time monitoring and mapping of hydraulic fracture operations in unconventional oil and gas plays.`,
                    `Member, Audit, Compensation and Human Resources, and Nominating, Governance and Sustainability Committees; 2024 Presiding Director`,
                  ],
                })
              }}
            />
            <CharacterCard
              listOrParagraph="list"
              name="Lynn Dugle"
              ratio="3/4"
              paragraphs={[
                "Director since 2023",
                "Former Chairman, President and Chief Executive Officer, Engility Holdings, Inc.",
              ]}
              imageURL={Dugle_Lynn}
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "LYNN A. DUGLE",
                  title: "Director since 2023",
                  paragraphs: [
                    `Ms. Dugle has more than 30 years of executive leadership and senior management experience in the defense, intelligence and high-tech industries. Ms. Dugle served as the Chief Executive Officer of Engility Holdings, Inc. (“Engility”), a publicly traded engineering and technology consulting company, from 2016 until the sale of the company in 2019. Ms. Dugle also served as Chairman and President of Engility.`,
                    `Prior to joining Engility in 2016, Ms. Dugle served more than a decade in senior management positions at Raytheon Company (“Raytheon”), a publicly traded company specializing in defense and other government markets worldwide, including Vice President, Engineering, Technology and Quality and President of Intelligence, Information and Services. Before joining Raytheon in 2004, Ms. Dugle held international and officer-level positions with ADC Telecommunications, Inc. Ms. Dugle began her career at Texas Instruments Incorporated, a global semiconductor design and manufacturing company, as a manufacturing engineer.`,
                    `Ms. Dugle currently serves as a director of Micron Technology, Inc. (since 2020), a publicly traded developer of memory and storage solutions, where she serves as a member of the Audit Committee and as Chair of the Security Committee. Ms. Dugle also currently serves as a director of KBR, Inc. (since 2020), a publicly traded provider of science, technology and engineering solutions, where she serves as the Chair of the Nominating and Corporate Governance Committee and as a member of the Compensation and Cybersecurity Committees.`,
                    `In addition, Ms. Dugle serves as a director of TE Connectivity Ltd. (since 2020), a publicly traded provider of connectivity, communications and sensing products, where she serves as a member of the Audit Committee.`,
                    `Ms. Dugle previously served on the board of directors of State Street Bank and Trust Company, including as Chair of the Technology, Operations and Cyber Committee and as a member of the Audit Committee. She also served as an Advisor Partner of First Light Acquisition Group Inc. and as a director of Avantus Federal before it was sold in 2022.`,
                    `Member, Audit, Compensation and Human Resources, and Nominating, Governance and Sustainability Committees`,
                  ],
                })
              }}
            />
            <CharacterCard
              listOrParagraph="list"
              name="Cris Gaut"
              ratio="3/4"
              paragraphs={[
                "Director since 2017",
                "Chairman of the Board, Forum Energy Technologies, Inc.",
                "Chairman: Audit Committee",
              ]}
              imageURL={Gaut_Chris}
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "C. CHRISTOPHER GAUT",
                  title: "Director since 2017",
                  paragraphs: [
                    `Mr. Gaut has extensive leadership experience and financial and operational expertise in the oilfield services, contract drilling and equipment sector of the oil and gas industry. He has served as a Chief Executive Officer, Chief Operating Officer and Chief Financial Officer for over 30 years for various public companies.`,
                    `Mr. Gaut has served as the Chairman of the Board and/or Chief Executive Officer of Forum Energy Technologies, Inc. (“Forum”) since August 2010. Effective February 2022, Mr. Gaut retired from his positions as President and Chief Executive Officer of Forum, and he became Forum’s Executive Chairman of the Board. In December of 2023, Mr. Gaut transitioned to non-executive Chairman of Forum. Forum is a leading energy manufacturing company, primarily serving oil service companies, drilling contractors as well as non- oil and gas customers.`,
                    `Prior to the formation of Forum, Mr. Gaut served as (i) a Managing Director of SCF Partners, a Houston based private equity firm investing in oilfield service and equipment companies, from 2009 until 2010; (ii) President of the Drilling and Evaluation Division of Halliburton Company (“Halliburton”), a publicly traded provider of oilfield services and products to the upstream oil and gas industry, from 2008 until 2009; and (iii) Halliburton’s Executive Vice President and Chief Financial Officer from 2003 to 2007.`,
                    `In addition, from 2008 until 2019, Mr. Gaut served on the Board of Directors of Valaris plc (formerly known as Ensco Rowan plc and as Ensco plc) (“Valaris”), a leading global offshore drilling contractor, where he also served as a member of the Nominating and Governance Committee. Mr. Gaut also previously served as Co-Chief Operating Officer of Valaris from January 2002 to February 2003, and as Senior Vice President and Chief Financial Officer of Valaris from December 1987 until February 2003.`,
                    `Further, from 2016 until 2019, Mr. Gaut served as a director of Key Energy Services, Inc., a publicly traded well service company, where he served as a member of the Audit Committee and as its lead independent director.`,
                    `Chairman, Audit Committee; Member, Compensation and Human Resources and Nominating, Governance and Sustainability Committees`,
                  ],
                })
              }}
            />
          </section>

          <section className="character-cards-container five-long spaced">
            <CharacterCard
              listOrParagraph="list"
              name="Mike Kerr"
              ratio="3/4"
              paragraphs={[
                "Director since 2020",
                "Former Equity Portfolio Manager, Capital Group",
              ]}
              imageURL={MikeKerr}
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "MICHAEL T. KERR",
                  title: "Director since 2020",
                  paragraphs: [
                    `Mr. Kerr, who retired from Capital Group effective October 1, 2020, has over 36 years of investment experience, including 35 years with Capital Group, one of the world's oldest and largest investment management organizations.`,
                    `During his tenure with Capital Group, Mr. Kerr managed multiple funds as an equity portfolio manager and covered global oil and gas companies for Capital Group as an equity investment analyst. As a result, Mr. Kerr has acquired extensive knowledge of the oil and gas exploration and production industry.`,
                    `Prior to joining Capital Group, Mr. Kerr was an exploration geophysicist with Cities Service Company. Mr. Kerr currently serves as a board member for various civic and non-profit organizations.`,
                    `Mr. Kerr is also a director of Kerry Group plc (since 2021), a publicly traded company and global leader in the development of taste and nutrition solutions for the food, beverage and pharmaceutical markets, where he serves as a member of the Audit Committee.`,
                    `Member, Audit, Compensation and Human Resources, and Nominating, Governance and Sustainability Committees`,
                  ],
                })
              }}
            />
            <CharacterCard
              listOrParagraph="list"
              name="Julie Robertson"
              ratio="3/4"
              paragraphs={[
                "Director since 2019",
                "Retired Chairman of the Board, President and Chief Executive Officer, Noble Corporation plc",
                "Chairperson: Compensation and Human Resources Committee",
              ]}
              imageURL={julie_roberston2}
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "JULIE J. ROBERTSON",
                  title: "Director since 2019",
                  paragraphs: [
                    `Ms. Robertson has extensive experience in the oil and gas industry and a wealth of knowledge of the drilling services sector from her 40-year career and various roles with Noble Corporation plc (“Noble”), a publicly traded company and one of the world's largest offshore drilling companies.`,
                    `Ms. Robertson served in the role of Executive Chairman of Noble from May 2020 until her retirement from Noble in February 2021. Previously, Ms. Robertson served as Chairman of the Board, President and Chief Executive Officer of Noble from January 2018 until May 2020. Ms. Robertson previously served in various other management roles for Noble and its subsidiaries, including (i) Executive Vice President from 2006 to January 2018, (ii) Senior Vice President — Administration from 2001 to 2006 and (iii) Vice President — Administration from 1996 to 2001. Ms. Robertson also served continuously as Corporate Secretary of Noble from 1993 until assuming the Chairman's role in 2018. Ms. Robertson joined a predecessor subsidiary of Noble in 1979.`,
                    `Ms. Robertson is also Chair of the Board of Seadrill Limited (since 2022), a publicly traded worldwide provider of offshore oil drilling services; a director of Superior Energy Services, Inc. (since 2021), a worldwide provider of specialized oilfield services and equipment; and a director of Patterson-UTI Energy, Inc. (since 2022), a publicly traded provider of drilling and pressure pumping services, directional drilling, rental equipment and technology to the oil and gas industry.`,
                    `Chairperson, Compensation and Human Resources Committee; Member, Audit and Nominating, Governance and Sustainability Committees `,
                  ],
                })
              }}
            />
            <CharacterCard
              listOrParagraph="list"
              name="Don Textor"
              ratio="3/4"
              paragraphs={[
                "Director since 2001",
                "Former Portfolio Manager, Dorset Energy Fund",
              ]}
              imageURL={Textor_Donald}
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "DONALD F. TEXTOR",
                  title: "Director since 2001",
                  paragraphs: [
                    `Mr. Textor was previously employed by Goldman, Sachs & Co., where he was a General Partner until his retirement in March 2001 and where he had 21 years of experience as the firm's senior security analyst for domestic oil and gas exploration and production companies. Mr. Textor also previously served as Portfolio Manager of the Dorset Energy Fund, an energy fund which invests primarily in the equity securities of companies in the energy industry.`,
                    `Mr. Textor previously served on the Board of Directors of Trilogy Energy Corp. (from 2005 until September 2017), where he also served as a member of the Compensation Committee. Trilogy Energy Corp., a petroleum and natural gas-focused Canadian energy corporation, merged with Paramount Resources Ltd. effective September 2017.`,
                    `As a result of serving in these roles and serving as a member of our Audit Committee since May 2001 (including as Chairman from May 2001 until February 2015), Mr. Textor has accumulated significant leadership and financial reporting experience as well as extensive knowledge of the oil and gas exploration and production industry.`,
                    `Member, Audit, Compensation and Human Resources, and Nominating, Governance and Sustainability Committees `,
                  ],
                })
              }}
            />
            <CharacterCard
              listOrParagraph="list"
              name="Ezra Yacob"
              ratio="3/4"
              paragraphs={[
                "Director since 2021",
                "Chairman of the Board and Chief Executive Officer, EOG Resources, Inc.",
              ]}
              imageURL={YACOB_Ezra}
              callbackFunc={() => {
                setModalState({
                  open: true,
                  name: "EZRA Y. YACOB",
                  title: "Director since 2021",
                  paragraphs: [
                    `Mr. Yacob was appointed Chairman of the Board, effective October 2022. Mr. Yacob was previously named EOG's Chief Executive Officer and appointed as a Director of EOG, effective October 2021. Mr. Yacob previously served as President from January 2021 through September 2021; Executive Vice President, Exploration and Production from December 2017 to January 2021; Vice President and General Manager in Midland from May 2014 to December 2017; and Division Exploration Manager in Midland and in Fort Worth from March 2012 to May 2014. Prior to that, Mr. Yacob served in various geoscience and leadership positions. He joined EOG in August 2005.`,
                    `Before joining EOG, Mr. Yacob worked for the Minerals Division at the United States Geological Survey.`,
                  ],
                })
              }}
            />
          </section>
          <section className="dxce-card-holder" style={{ paddingBottom: "50px" }}>
            <AllMightyCard
              icon={faUsers}
              iconColor={"black"}
              contentAlign="center"
              centerAll
              button={
                <Button
                  className="dxce-card-button"
                  onClick={() => {
                    setModalState({
                      open: true,
                      name: "Contact the Board",
                      paragraphs: [
                        `Stockholders and other interested persons may contact the Board of Directors in writing, addressed to:`,
                        `<Recipient or Recipients>`,
                        `c/o Michael P. Donaldson`,
                        `EOG Resources, Inc.`,
                        `1111 Bagby, Sky Lobby 2`,
                        ` Houston, TX 77002`,
                        `Board communications that the sender wishes to be confidential should be clearly marked “CONFIDENTIAL.”`,
                      ],
                    })
                  }}
                >
                  Contact the Board
                </Button>
              }
            />
            <AllMightyCard
              icon={faCalculator}
              iconColor={"black"}
              contentAlign="center"
              centerAll
              button={
                <Button
                  className="dxce-card-button"
                  onClick={() => {
                    setModalState({
                      open: true,
                      name: "Contact Audit Committee",
                      paragraphs: [
                        `To contact the Audit Committee regarding accounting or audit issues, please send a letter in an envelope clearly marked “CONFIDENTIAL” addressed to:`,
                        `Audit Committee`,
                        `c/o Michael P. Donaldson`,
                        `EOG Resources, Inc.`,
                        `1111 Bagby, Sky Lobby 2`,
                        `Houston, TX 77002`,
                      ],
                    })
                  }}
                >
                  Contact Audit Committee
                </Button>
              }
            />
            <AllMightyCard
              icon={faPhone}
              iconColor={"black"}
              contentAlign="center"
              centerAll
              button={
                <Button
                  className="dxce-card-button"
                  component={"a"}
                  target="_blank"
                  href="https://secure.ethicspoint.com/domain/media/en/gui/74446/index.html"
                >
                  Ethics & Compliance Hotline
                </Button>
              }
            />
          </section>
          <h2 className="board-subheader">Committees</h2>
          <Separator width="70%" />
          <section className="dxce-card-holder" style={{ paddingBottom: "50px" }}>
            <div className="db-card" style={{ justifyContent: "inherit" }}>
              <h2>Audit</h2>
              <p>
                Chairman: Cris Gaut
                <br />
                Members: Janet Clark, Charlie Crisp, Bob Daniels,
                <br />
                Lynn Dugle, Mike Kerr, Julie Robertson,
                <br /> Don Textor
              </p>
              <a href={audit_charter} target="_blank" rel="noreferrer">
                <Button className="dxce-card-button">CHARTER</Button>
              </a>
            </div>
            <div className="db-card" style={{ justifyContent: "inherit" }}>
              <h2>Compensation and Human Resources</h2>
              <p>
                Chairperson: Julie Robertson
                <br />
                Members: Janet Clark, Charlie Crisp, Bob Daniels,
                <br />
                Lynn Dugle, Cris Gaut, Mike Kerr,
                <br /> Don Textor
              </p>
              <a href={comp_charter} target="_blank" rel="noreferrer">
                <Button className="dxce-card-button">CHARTER</Button>
              </a>
            </div>
            <div className="db-card" style={{ justifyContent: "inherit" }}>
              <h2>Nominating, Governance and Sustainability</h2>
              <p>
                Chairperson: Janet Clark
                <br />
                Members: Charlie Crisp, Bob Daniels, Lynn Dugle,
                <br />
                Cris Gaut, Mike Kerr, Julie Robertson,
                <br /> Don Textor
              </p>
              <a href={nomgov_charter} target="_blank" rel="noreferrer">
                <Button className="dxce-card-button">CHARTER</Button>
              </a>
            </div>
          </section>

          <h2 id="governance" className="board-subheader">
            Governance
          </h2>
          <Separator width="70%" />

          <section
            className="dxce-card-holder max-width-container page"
            style={{ paddingBottom: "50px" }}
          >
            <div className="pdf-cards-section">
              <div className="db-pdf-link">
                <a href={corpgov_guidelines} target="_blank" rel="noreferrer">
                  <Button className="pdf-card-button">PDF</Button>
                </a>
                <p>Corporate Governance Guidelines</p>
              </div>
              <div className="db-pdf-link">
                <a href={conduct_employees} target="_blank" rel="noreferrer">
                  <Button
                    style={{ marginBottom: "30px" }}
                    className="pdf-card-button"
                  >
                    PDF
                  </Button>
                </a>
                <p>
                  Code of Business Conduct and Ethics for Directors, Officers and
                  Employees
                </p>
              </div>
              <div className="db-pdf-link">
                <a href={conduct_vendors} target="_blank" rel="noreferrer">
                  <Button className="pdf-card-button">PDF</Button>
                </a>
                <p>
                  Code of Business Conduct and Ethics for Vendors and Contractors
                </p>
              </div>
              <div className="db-pdf-link">
                <a href={communications_board} target="_blank" rel="noreferrer">
                  <Button className="pdf-card-button">PDF</Button>
                </a>
                <p>Procedures for Communications with the Board of Directors</p>
              </div>
              <div className="db-pdf-link">
                <a href={complaint_audit} target="_blank" rel="noreferrer">
                  <Button className="pdf-card-button">PDF</Button>
                </a>
                <p>Complaint Procedures for Accounting and Auditing Matters</p>
              </div>
              <div className="db-pdf-link">
                <a href={ethics_sfo} target="_blank" rel="noreferrer">
                  <Button className="pdf-card-button">PDF</Button>
                </a>
                <p>Code of Ethics for Senior Financial Officers</p>
              </div>
              <div className="db-pdf-link">
                <a href={bylaws} target="_blank" rel="noreferrer">
                  <Button className="pdf-card-button">PDF</Button>
                </a>
                <p>Bylaws</p>
              </div>
              <div className="db-pdf-link">
                <a
                  href={political_activities_and_contributions}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="pdf-card-button">PDF</Button>
                </a>
                <p>Political Activities and Trade Associations Policy</p>
              </div>

              <div className="db-pdf-link">
                <a href={human_rights} target="_blank" rel="noreferrer">
                  <Button className="pdf-card-button">PDF</Button>
                </a>
                <p>Human Rights Policy</p>
              </div>
            </div>

          </section>
          <PeopleModal
            {...modalState}
            onClose={onClose}
            htmlContent={htmlContent}
          />
        </div>
      </div >
    </Layout >
  )
}
